import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A Conviction is a statement of belief to the Luminary, something that is not true but that the `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronist`}</a>{` firmly believes is true. When `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{` is cast on Naos, the wielder must form a Conviction strong enough to convince `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{` that whatever they believe is reality, thus manifesting their belief in the physical world.`}</p>
    <p>{`The difficulty of manifesting a successful Conviction is directly proportional to the number of things that would need to change about reality in order to avoid contradictions. If an Acronist pushes a Conviction too hard, and the contradictions are too severe, there is a risk of `}<em parentName="p">{`Thread Fatigue`}</em>{`, leaving the Acronist unable to form Convictions until they have mentally recovered. This can leave the user in a state of both physical and mental exhaustion for a period of time.`}</p>
    <p>{`If a Conviction is strong enough, it can become a `}<a parentName="p" {...{
        "href": "/Fixed%20Truth",
        "title": "Fixed Truth"
      }}>{`Fixed Truth`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      